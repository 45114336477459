<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    filterPanelBlocks: string[][];
    filterTitles: string[];
    xValues: string[];
    xValues2?: string[]; // second dropdown for index
    xValuesTitles?: string[];
    xValues2Titles?: string[];
    yValues?: string[];
    scenarios?: string[];
    years?: string[];
    disableYears?: boolean;
    hideYears?: boolean;
    year?: string;
    scenario?: string;
    x?: string;
    y?: string;
    xTitle?: string;
    isBarChart?: boolean;
    isDoubleChart?: boolean;
    isCompanyRanking?: boolean;
  }>(),
  {
    xValues2: () => [],
    xValuesTitles: () => [],
    xValues2Titles: () => [],
    yValues: () => [],
    scenarios: () => [],
    years: () => ["2030", "2050"],
    year: "",
    scenario: "",
    x: "",
    y: "",
    xTitle: "",
  },
);

const emit = defineEmits([
  "onXSelect",
  "onX2Select",
  "onYSelect",
  "onFilterChange",
  "onClearClick",
  "onScenario",
  "onYear",
  "onSort",
  "onTitleUpdate",
]);

const onFilterChange = (checked: boolean, id: string, index: number) => {
  emit("onFilterChange", checked, id, index);
};

const yearToShow = computed(() =>
  props.x !== "Companies" ? props.years.join(" & ") : props.year,
);

const xToShow = computed(() => {
  return props.xValuesTitles.length && props.x
    ? props.xValuesTitles[props.xValues.indexOf(props.x)]
    : props.x;
});

const title = computed(() =>
  props.isCompanyRanking
    ? `Companies’ ${String(props.year)} ${String(xToShow.value)}`
    : `${String(xToShow.value)}' ${
        props.isBarChart
          ? "Cost Breakdown"
          : (props.y === "EBIT Margin" || props.y === "Costs") &&
              props.scenario === "All Scenarios"
            ? String(props.y) + " Range"
            : String(props.y)
      } in ${
        String(props.scenario) +
        (props.scenario === "All Scenarios" ? "" : " Scenario")
      } in ${String(yearToShow.value)}`,
);

watch(
  title,
  (value) => {
    emit("onTitleUpdate", value);
  },
  { immediate: true },
);

const onScenario = (checked: boolean, title: string) => {
  if (
    props.scenario === "High Climate Impact" &&
    props.y === "Feed Additives"
  ) {
    const el = document.getElementById("yBreakdown");
    const selected = el?.children[0]?.children[0]?.children[1];
    if (selected) selected.innerHTML = "Costs";
  }
  emit("onScenario", title);
};
</script>

<template>
  <div class="chart-controls">
    <div
      class="flex flex-wrap items-center justify-between pb-2 pt-4 md:flex-nowrap"
    >
      <h3 class="mb-3 md:mb-0">
        {{ title }}
      </h3>

      <UiFilterPanel
        v-if="!isCompanyRanking"
        class="chart-controls__filters"
        :titles="filterTitles"
        :blocks="filterPanelBlocks"
        :disabled="isDoubleChart"
        scroll-on-open
        @on-filter-change="onFilterChange"
        @on-clear-click="emit('onClearClick', $event)"
      />
    </div>

    <div class="mt-1 items-center justify-between md:mt-3 xl:flex">
      <div class="crt-dropdowns flex">
        <UiDropdown
          :label="xTitle || 'X Value'"
          :items="xValues"
          :items-titles="xValuesTitles"
          :initial="xValues[0]"
          @select="
            (value: string, index: number) => emit('onXSelect', value, index)
          "
        />
        <UiDropdown
          v-if="xValues2 && xValues2.length > 0"
          id="kpi"
          class="ml-2"
          label="KPI"
          :items="xValues2"
          :items-titles="xValues2Titles"
          :initial="xValues2[0]"
          @select="
            (value: string, index: number) => emit('onX2Select', value, index)
          "
        />
        <UiDropdown
          v-if="yValues && yValues.length"
          :id="isBarChart ? 'yBreakdown' : 'yDropdown'"
          class="ms-3"
          label="Y Value"
          :items="yValues"
          :initial="yValues[0]"
          placeholder="Select a specific KPI"
          @select="
            (value: string, index: number) => emit('onYSelect', value, index)
          "
        />
      </div>

      <div
        v-if="!hideYears"
        class="chart-years mt-3 hidden items-center md:flex xl:mt-0"
      >
        <UiRadio
          v-if="scenarios && scenarios.length"
          :items="scenarios"
          class="me-3"
          @select="onScenario"
        />
        <UiRadio
          :disabled="disableYears"
          :items="years"
          class="ms-3"
          @select="(checked: boolean, title: string) => emit('onYear', title)"
        />
      </div>

      <div v-if="isCompanyRanking" class="flex">
        <div class="mt-3 hidden items-center md:flex xl:mt-0">
          <UiSwitch
            id="sortButton"
            class="mr-3"
            :items="['High to Low', 'Low to High']"
            :initial="'High to Low'"
            @select="emit('onSort', $event)"
          />
        </div>

        <UiFilterPanel
          class="chart-controls__filters"
          :titles="filterTitles"
          :blocks="filterPanelBlocks"
          :disabled="isDoubleChart"
          scroll-on-open
          @on-filter-change="onFilterChange"
          @on-clear-click="emit('onClearClick', $event)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chart-controls {
  .chart-controls__filters {
    @apply max-sm:w-full;
  }

  &:deep(.dropdown) {
    @apply max-sm:flex-grow;
  }

  &:deep(#sort-button) {
    min-width: 160px;
  }
}
</style>
